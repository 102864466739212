.swiper-pagination {
  text-align: left;
  padding-bottom: 8px;
}

.swiper-pagination-bullets .swiper-pagination-bullet {
  border: 1px solid var(--st-brand-blue-900);
  opacity: 1;
  background: transparent;
}
.swiper-pagination-bullets .swiper-pagination-bullet-active {
  background-color: var(--st-brand-blue-900);
}

.center-slides .swiper-wrapper {
  justify-content: center;
}
