:root {
  /* background */
  --st-bg: #ffffff;
  --st-bg-muted: #f9fafb;
  --st-bg-inverted: #111827;

  --st-bg-light: #fafdff;
  --st-bg-footer: #11374d;

  --st-bg-section-purple: #f0f0fc;
  --st-bg-section-pink: #fcf0fc;
  --st-bg-section-green: #edfaf3;
  --st-bg-section-orange: #fcefed;
  --st-bg-section-blue: #e8f4fa;
  --st-bg-section-blue-light: rgba(232, 244, 250, 0.4);

  /* colors */
  --st-brand-maroon-400: #ba64ba;
  --st-brand-maroon-900: #673467;
  --st-brand-orange-600: #ec6950;
  --st-brand-orange-500: #e67102;
  --st-brand-orange-400: #ff790c;
  --st-brand-orange-300: #fa8b73;
  --st-brand-blue-900: #11374d;
  --st-brand-blue-800: #242778;
  --st-brand-blue-900-opacity-20: rgba(17, 55, 77, 0.2);
  --st-brand-blue-700: #014f6b;
  --st-brand-blue-500: #0097ce;
  --st-brand-blue-400: #0097ce;
  --st-brand-blue-300: #4449c9;
  --st-brand-blue-200: #1ca4de;
  --st-brand-blue-100: #ebfaff;
  --st-brand-green-600: #17723c;
  --st-brand-green-500: #30b695;
  --st-brand-green-550: #2eb695;
  --st-brand-green-400: #def9f4;
  --st-brand-green-300: #1fb896;
  --st-brand-green-200: #4adfb7;
  --st-brand-red-500: #bf1650;
  --st-brand-red-100: #fcefed;
  --st-brand-black-100: rgba(0, 0, 0, 0.1);
  --st-brand-yellow-400: #efd484;

  --st-text-main: #111111;
  --st-text-muted: #6b7280;
  --st-text-blue: #11374d;
  --st-text-blue-medium: #014f6b;
  --st-text-blue-light: #0097ce;
  --st-text-blue-light-2: #1ea5de;
  --st-text-blue-dark: #242778;
  --st-text-white: #ffffff;
  --st-text-green: #17723c;
  --st-text-maroon: #673467;
  --st-text-orange: #ec6950;
}

.dark {
  /* background */
  --st-bg: #111111;
  --st-bg-muted: #101010;
  --st-bg-inverted: #f3f4f6;

  /* colors */
  --st-text-main: #ffffff;
  --st-text-muted: #9ca3af;
}
