$white: #fff;
$black: #000;
$baseUrlAssets: "https://www2.simpletherapy.com";

// Care First
$care-first-primary: #007298;
$care-first-secondary: #0099cc;
$care-first-tertiary: #003359;
$care-first-bg: #ecf3fb;
$care-first-primary-bg: #ebfaff;
$care-first-grey-d6: #d6d6d6;
$care-first-dark: #303030;

// simple therapy
$befault-primary: #e67101;
$simple-therapy-grey-70: #707070;
$simple-therapy-grey-80: #808080;
$default-success: #2eb695;
$default-primary-gradient: linear-gradient(89.6deg, #0097ce 4.46%, #1fb896 90.02%);
$default-grey-f2: rgba(242, 242, 242, 0.5);
