body {
  font-family: "Avenir" sans-serif;
  color: var(--st-text-blue-medium);
  white-space: break-spaces;
}

body * {
  font-family: "Avenir" sans-serif;
}

select {
  background-position: calc(100% - 0.75rem) center !important;
}

.accordion-item:hover:after {
  transform: rotate(180deg);
}

.check-out-our-member-video .react-player__preview {
  border-radius: 1.5rem;
}
