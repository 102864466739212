.gettingstartedcard {
  position: relative;
  @media only screen and (min-width: 768px) {
    padding-left: 4.5rem;
  }
  @media only screen and (max-width: 767px) {
    //padding-left: 3rem;
    text-align: center;
  }
  &::before {
    counter-increment: section;
    content: counter(section);
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid $default-success;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $default-success;
    opacity: 0.7;
    transform: translateY(-0.5rem);

    @media only screen and (min-width: 768px) {
      font-size: 1.42rem;
      width: 2.57rem;
      height: 2.57rem;
    }
    @media only screen and (max-width: 767px) {
      width: 2rem;
      height: 2rem;
      font-size: 1rem;
      left: 50%;
      transform: translate(-50%, -2.5rem);
    }
  }

  &:not(:first-child) {
    @media only screen and (min-width: 992px) {
      margin-top: 3.21rem;
    }
    @media only screen and (max-width: 992px) {
      margin-top: 4.5rem;
    }
  }
  @media only screen and (max-width: 992px) {
    margin-top: 4rem;
  }
  h4 {
    font-weight: 900;
    color: $simple-therapy-grey-80;
  }
  h3 {
    font-weight: 600;
    margin-bottom: 0;
    color: $simple-therapy-grey-80;
    @media only screen and (max-width: 767px) {
      font-size: 1.3rem;
    }
  }
  p {
    margin-top: 1rem;
    margin-bottom: 0;
    color: $black;
    display: none;
    @media only screen and (min-width: 992px) {
      font-size: 1.57rem;
      max-width: 35.285rem;
      line-height: 1.47;
    }
    @media only screen and (max-width: 992px) {
      font-size: 1.1rem;
      line-height: 1.3;
    }
  }
  &.active {
    &::before {
      opacity: 1;
    }
    p {
      display: block;
    }
    h4 {
      color: $black;
    }
    h3 {
      color: $black;
    }
  }
}

.carefirst-bg-primary {
  background-color: $care-first-tertiary;
}
.register-section {
  background-color: $care-first-tertiary;

  @include respond(desktop) {
    padding-top: 17rem !important;
  }
  @include respond(desktopIpad) {
    overflow: hidden;
    position: relative;
  }
  @include respond(ipad) {
    padding-top: 11rem !important;
  }
  &::after {
    @include respond(desktopIpad) {
      content: "";
      height: 100%;
      width: 200%;
      background-color: #ffffff;
      left: 50%;
      position: absolute;
      border-radius: 75%;
      bottom: 0;
    }
    @include respond(desktop) {
      transform: translate(-50%, -33.4rem) rotateY(130deg) rotateX(0deg);
    }
    @include respond(ipad) {
      transform: translate(-50%, -19.4rem) rotateY(130deg) rotateX(0deg);
    }
  }
  .carefirst-heading-2 {
    color: $white;

    @include respond(desktop) {
      margin: 0 auto rem(60) auto;
      max-width: rem(1100);
    }
    @include respond(ipadMobile) {
      margin-bottom: rem(30);
    }
  }
}
