.btn {
  font-weight: 500;
  letter-spacing: normal;
  white-space: nowrap;
  border-radius: 5px;
  border-radius: 6px !important;
  text-decoration: none;
  color: $white !important;
}
.care-first-btn-primary {
  font-weight: 600 !important;
  background-color: $care-first-primary !important;
  &:hover {
    background-color: $care-first-tertiary !important;
  }
  @include respond(desktop) {
    padding: rem(19) rem(63) !important;
    font-size: rem(24) !important;
  }
  @include respond(ipad) {
    padding: rem(10) rem(30) !important;
    font-size: rem(14) !important;
  }
  @include respond(mobile) {
    padding: rem(7) rem(20) !important;
    font-size: rem(14) !important;
  }
  @include respond(mediumMobile) {
    padding: rem(7) rem(16) !important;
  }

  &.dropdown-toggle {
    background-color: $care-first-secondary !important;
    min-width: 28.5rem;
    display: block;
    border: 1px solid transparent;
    //font-size: rem(28) !important;
    line-height: 1.25;
    &:hover {
      background-color: $care-first-tertiary !important;
    }
    &:focus {
      box-shadow: none !important;
      border-color: transparent !important;
    }
    &:after {
      display: none !important;
    }

    @include respond(ipad) {
      min-width: 20rem;
      margin: 0 auto;
      font-size: rem(18) !important;
    }
    @include respond(mobile) {
      min-width: 16.5rem;
      margin: 0 auto;
      font-size: rem(18) !important;
    }
  }
}

.default-btn-primary {
  background-color: $befault-primary !important;
  border-radius: 5px !important;
  font-weight: 500 !important;
  line-height: 1.2 !important;
  border: 2px solid $befault-primary !important;
  @include respond(desktop) {
    font-size: rem(28) !important;
    padding: rem(14) rem(54) !important;
  }
  @include respond(ipadMobile) {
    font-size: rem(16) !important;
    padding: rem(8) rem(20) !important;
  }
  &:hover {
    background: #ffffff !important;
    color: $befault-primary !important;
    border: 2px solid $befault-primary;
  }
}
