.accordion {
  .accordion-item {
    &:not(:first-child) {
      @include respond(desktop) {
        margin-top: rem(33);
      }
      @include respond(ipadMobile) {
        margin-top: rem(25);
      }
    }
  }
  .accordion-body {
    background-color: white;
    @media only screen and (min-width: 992px) {
      font-size: 1.57rem;
      line-height: 1.47;
      padding: 1rem 1.25rem 1rem 7.25rem;
    }
    @media only screen and (max-width: 992px) {
      font-size: 1.1rem;
      line-height: 1.3;
    }
  }

  .accordion-button {
    font-weight: 600;
    box-shadow: 0px 2px 15px rgb(0 0 0 / 20%) !important;
    background-color: $white !important;
    color: $black !important;
    &::after {
      background-image: url("#{$baseUrlAssets}/svg/arrow.svg") !important;
      transform: rotate(90deg);
      @include respond(desktop) {
        height: 2.25rem;
      }
      @include respond(ipadMobile) {
        width: rem(10);
        height: rem(20);
        background-size: contain;
      }
    }
    &:not(.collapsed) {
      background-color: $white;
      border-color: $default-success;
      &::after {
        transform: rotate(-90deg);
      }
    }
    @include respond(desktop) {
      font-size: rem(33);
      padding: 2.4rem 3.928rem 2.4rem 7.57rem;
    }
    @include respond(ipadMobile) {
      font-size: rem(15);
      padding: 1.3rem;
    }
  }
}
