@import "./base/_colors.css";
@import "./base/_fonts.css";
@import "./base/_base.css";
@import "./components/_slider.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-white-arrow-right {
    @apply relative mt-4 inline-flex rounded-full py-2 pl-14 pr-20 text-white after:absolute after:right-12 after:top-1/2 after:h-6 after:w-6 after:-translate-y-1/2 after:bg-[url('/images/icons/arrow-right-white.svg')] after:bg-center after:bg-no-repeat;
  }

  .form-input {
    @apply mb-2.5 w-full rounded-lg bg-brand-bg-section-blue-light px-2.5 py-3.5 text-base font-light placeholder:text-base placeholder:font-light placeholder:text-brand-text-blue;
  }

  .form-select {
    @apply appearance-none border-0 bg-[url('/images/icons/select-arrow.svg')] bg-center bg-no-repeat;
  }
  .error-msg {
    @apply mb-2 text-base font-light text-[--st-brand-red-500];
  }
  .icon-before-text {
    @apply relative pl-8 before:absolute before:left-0 before:h-6 before:w-6 before:bg-center before:bg-no-repeat;
  }
}

.container {
  @apply max-w-[1440px];
}
