.default-heading-1 {
  font-weight: 500;
  color: $black;
  line-height: 1.3;

  @include respond(desktop) {
    font-size: rem(63);
  }
  @include respond(ipadMobile) {
    font-size: rem(35);
  }
}
.default-heading-2 {
  font-weight: 500;
  color: $black;
  line-height: 40px;
  text-align: center;

  @include respond(desktop) {
    font-size: rem(45);
    margin-bottom: rem(80);
  }
  @include respond(ipadMobile) {
    font-size: rem(25);
    margin-bottom: rem(40);
  }
}

// Care First
.carefirst-heading-1,
.carefirst-heading-3,
.carefirst-heading-2 {
  font-weight: 400;
  color: $black;
  font-family: "Oswald", sans-serif;
}
.carefirst-heading-1 {
  line-height: 1.3;

  @include respond(desktop) {
    font-size: rem(70);
  }
  @include respond(ipadMobile) {
    font-size: rem(35);
  }
}
.carefirst-heading-3 {
  line-height: 1;
  color: $care-first-secondary;

  @include respond(desktop) {
    font-size: rem(50);
  }
  @include respond(ipadMobile) {
    font-size: rem(25);
  }
}
.carefirst-heading-2 {
  line-height: 1.3;

  @include respond(desktop) {
    font-size: rem(60);
  }
  @include respond(ipadMobile) {
    font-size: rem(30);
  }
}
.carefirst-desc {
  margin-bottom: 0;
  color: $care-first-tertiary;
  @include respond(desktop) {
    font-size: rem(24);
  }
  @include respond(ipadMobile) {
    font-size: rem(18);
  }
}

.everything-you-need-section-carefirst {
  .carefirst-heading-2,
  p {
    color: $care-first-tertiary;
  }
}
