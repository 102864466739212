body {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    @include respond(desktop) {
      max-width: rem(1274) !important;
    }
  }
}
