@mixin respond($breakpoint) {
  @if $breakpoint==smalldesktop {
    @media only screen and (min-width: 992px) and (max-width: 1400px) {
      @content;
    }
  }

  @if $breakpoint==mediumdesktop {
    @media only screen and (min-width: 992px) and (max-width: 1919px) {
      @content;
    }
  }

  @if $breakpoint==largedesktop {
    @media only screen and (min-width: 1920px) {
      @content;
    }
  }

  @if $breakpoint==extralargedesktop {
    @media only screen and (min-width: 2400px) {
      @content;
    }
  }

  @if $breakpoint==desktop {
    @media only screen and (min-width: 992px) {
      @content;
    }
  }

  @if $breakpoint==ipad {
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  }

  @if $breakpoint==desktopIpad {
    @media only screen and (min-width: 768px) {
      @content;
    }
  }

  @if $breakpoint==ipadProMobile {
    @media only screen and (max-width: 1120px) {
      @content;
    }
  }

  @if $breakpoint==ipadMobile {
    @media only screen and (max-width: 991px) {
      @content;
    }
  }

  @if $breakpoint==mobile {
    @media only screen and (max-width: 767px) {
      @content;
    }
  }

  @if $breakpoint==mediumMobile {
    @media only screen and (max-width: 540px) {
      @content;
    }
  }

  @if $breakpoint==smallmobile {
    @media only screen and (max-width: 360px) {
      @content;
    }
  }
}
$em-base: 16;

//functions
@function rem($size, $base: $em-base) {
  $remSize: ($size / $em-base);
  @return #{$remSize}rem;

  // $remSize: ($size  /  $em-base);
  //   @return #{math.div($size, $em-base)}rem;
}
