.dropdown {
  &.dropdown-locale {
    width: 125px;
    min-width: 125px;
    @media only screen and (max-width: 767px) {
      width: 110px;
      min-width: 110px;
    }
    @media only screen and (max-width: 445px) {
      width: 90px;
      min-width: 90px;
    }
    .btn {
      color: #000000 !important;
    }
    .btn-primary {
      width: 125px;
      background-color: transparent;
      border-color: transparent;
      color: #000000;
      @media only screen and (max-width: 767px) {
        width: 110px;
        padding-right: 0;
      }
      @media only screen and (max-width: 445px) {
        width: 90px;
      }
      &:hover,
      &:focus {
        color: #000000;
        background-color: transparent;
        border-color: transparent;
        box-shadow: 0 0 0 0 transparent;
      }
    }
    .dropdown-toggle {
      &:after {
        display: none;
      }
      @media only screen and (max-width: 445px) {
        padding-right: 0;
      }
      img {
        @media only screen and (max-width: 767px) {
          width: 30px;
        }
        @media only screen and (max-width: 445px) {
          margin-left: 0 !important;
        }
      }
    }
    &:after {
      position: absolute;
      top: 40%;
      right: 15px;
      display: inline-block;
      content: "";
      border: solid black;
      border-width: 0 2px 2px 0;
      padding: 3px;
      transform: rotate(45deg) translateY(0%);
      @media only screen and (max-width: 767px) {
        right: 8px;
      }
      @media only screen and (max-width: 445px) {
        right: 0;
      }
    }
    &.show {
      .btn-primary {
        &.dropdown-toggle {
          background: transparent;
          border-color: transparent;
          &:hover,
          &:focus {
            color: #000000;
            background-color: transparent;
            border-color: transparent;
            box-shadow: 0 0 0 0 transparent;
          }
        }
      }
      .dropdown-toggle {
        background: transparent;
      }
      &:after {
        top: 45%;
        right: 12px;
        border-width: 0 2px 2px 0;
        transform: rotate(225deg) translateY(-40%);
        @media only screen and (max-width: 767px) {
          right: 5px;
        }
        @media only screen and (max-width: 445px) {
          right: -3px;
        }
      }
    }

    .dropdown-menu {
      min-width: 125px;
      padding: 0;
      border: none;
      box-shadow: 0px 5.04597px 10.0919px rgba(0, 0, 0, 0.160784);
      margin-top: -5px;
      @media only screen and (max-width: 767px) {
        min-width: 110px;
      }
      @media only screen and (max-width: 445px) {
        min-width: 90px;
      }
    }
    .dropdown-item {
      height: 50px;
      &:active {
        background-color: #e9ecef;
        color: #000000;
      }
      img {
        margin-right: 7px;
        margin-left: 3px;
        @media only screen and (max-width: 767px) {
          width: 30px;
        }
      }
    }
  }
}
