html.old-version {
  @import "~bootstrap/scss/bootstrap-grid";
  @import "~bootstrap/scss/bootstrap-utilities";
  @import "~bootstrap/scss/transitions";
  @import "~bootstrap/scss/modal";
  @import "~bootstrap/scss/dropdown";
  @import "~bootstrap/scss/accordion";

  @import "./helper/mixin";
  @import "./helper/helpers";
  @import "./base/variables";
  @import "./components/simple-therapy";
  @import "./components/default";
  @import "./components/dropdown";
  @import "./components/heading";
  @import "./components/container";
  @import "./components/section";
  @import "./components/button";
  @import "./components/slider";
  @import "./components/accordion";

  scroll-behavior: smooth;
  font-family: "Avenir", sans-serif;
  font-weight: 300;

  font-size: 14px;
  @media only screen and (min-width: 992px) and (max-width: 1280px) {
    font-size: 11px;
  }

  .default-home-page {
    font-family: "Avenir", sans-serif !important;
    font-weight: 400;
  }

  .carefirst-home-page {
    font-family: "Open Sans", sans-serif !important;
    font-weight: 400;
  }

  .oswald-font {
    font-family: "Oswald", sans-serif !important;
  }

  .container {
    max-width: 1114px;
  }
}
