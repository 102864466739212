.combineSlider {
  .slick-arrow {
    &::before {
      content: "";
      position: absolute;
      border-right: 3px solid $simple-therapy-grey-70;
      border-bottom: 3px solid $simple-therapy-grey-70;
      top: 0;
      @include respond(desktop) {
        width: 1.5rem;
        height: 1.5rem;
      }
      @include respond(ipadMobile) {
        width: 1.2rem;
        height: 1.2rem;
      }
    }
    z-index: 2;
  }
  .slick-track {
    display: flex;
  }
  .slick-slide {
    @include respond(desktop) {
      margin: 0 rem(16) 0 0;
    }
    @include respond(ipadMobile) {
      margin: 0 rem(10) 0 0;
    }
  }
  .slick-next {
    @include respond(desktopIpad) {
      right: 0 !important;
    }
    @include respond(ipadMobile) {
      right: 1rem !important;
    }
    &::after {
      right: 0;
    }
    &::before {
      transform: rotate(-45deg);
    }
  }
  .slick-prev {
    @include respond(desktop) {
      left: -0.714rem !important;
    }
    @include respond(ipadMobile) {
      left: rem(20) !important;
    }
    &::before {
      left: -1.785rem;
      transform: rotate(135deg);
    }
  }
}

.care-first-primary-bg {
  background-color: $care-first-primary-bg;
}

.video-slider-card-wrapper {
  max-width: 800px !important;
  margin: 0 auto;

  .slick-arrow {
    &::before {
      content: "";
      position: absolute;
      border-right: 3px solid $simple-therapy-grey-70;
      border-bottom: 3px solid $simple-therapy-grey-70;
      top: 0;
      @include respond(desktopIpad) {
        width: 1.5rem;
        height: 1.5rem;
      }
      @include respond(mobile) {
        width: 1.2rem;
        height: 1.2rem;
      }
    }
    z-index: 2;
  }
  .slick-next {
    @include respond(desktop) {
      right: -3rem !important;
    }
    @include respond(ipad) {
      right: 6rem !important;
    }
    @include respond(mobile) {
      right: 0;
    }
    &::before {
      transform: rotate(-45deg);
    }
    &::after {
      right: 0;
    }
  }
  .slick-prev {
    @include respond(ipad) {
      left: 7rem !important;
    }
    @include respond(mobile) {
      left: 20px;
    }
    &::before {
      left: -1.785rem;
      transform: rotate(135deg);
    }
  }
}
.video-slider-card {
  margin: 0 auto;
  border-radius: 5px;

  @include respond(desktop) {
    width: 42.71rem;
  }
  @include respond(ipad) {
    width: 30.71rem;
  }
  @include respond(mobile) {
    margin: 0 rem(30);
  }
  &__desc {
    text-align: center;
    @include respond(desktop) {
      margin-top: rem(40);
    }
    @include respond(ipadMobile) {
      margin-top: rem(30);
    }
    h3 {
      font-weight: 600;
      @include respond(desktop) {
        font-size: rem(40);
      }
      @include respond(ipadMobile) {
        font-size: rem(30);
      }
    }
    h4 {
      @include respond(desktop) {
        font-size: rem(22);
      }
      @include respond(ipadMobile) {
        font-size: rem(16);
      }
    }
  }
  &__videoicon {
    display: inline-block;
    position: absolute;
    width: 4.142857rem;
    height: 4.142857rem;
    border-radius: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    background-color: rgba($color: #000000, $alpha: 0.5);
  }
  &__logo {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 54px;
    padding: 1.4285714286rem;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 133px;
    height: 44px;
    background: #fff url("#{$baseUrlAssets}/svg/simple-therapy-logo.svg") no-repeat center;
    background-size: 90%;
  }
  &__highlightname {
    display: inline-block;
    position: absolute;
    left: 80px;
    bottom: 68px;
    font-size: 3.142857rem;
    font-weight: 700;
    color: $white;
  }
}
