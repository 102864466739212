.default-jumbotron-section {
  @include respond(desktop) {
    // padding: rem(120) 0;
  }
}
.carefirst-jumbotron-section {
  background-color: $care-first-bg;
  @include respond(desktop) {
    padding: 0 0 13.5rem 0;
    position: relative;
  }
  @include respond(ipad) {
    padding: rem(44) 0 rem(120) 0;
    position: relative;
  }
  @media only screen and (max-width: 1439px) {
    overflow: hidden;
  }
  .carefirst-heading-1,
  p {
    color: $care-first-tertiary;
  }
}
.program-benefits-section {
  position: relative;
  background-color: $care-first-primary-bg;

  @include respond(desktop) {
    padding: rem(65) 0 rem(95) 0;
  }
  @include respond(ipadMobile) {
    padding: rem(65) rem(17);
  }
  [class*="col-"] {
    &:first-child {
      .program-benefits-card {
        margin-top: 0;
      }
    }
  }
}
.program-benefits-section-wrapper {
  position: relative;
  .benefits-img-bottom {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    transform: translateY(-54%);
  }
}

.default-jumbotron-section {
  @include respond(desktop) {
    .new-banner {
      transform: none;
    }
    .apps {
      padding-top: rem(100);
      transform: none;
    }
  }
  @include respond(ipadMobile) {
    text-align: center;
    padding: 0 rem(17) rem(65) rem(17);
    .apps {
      justify-content: center;
      flex-wrap: wrap;
      a {
        &:last-child {
          margin-top: rem(20);
        }
      }
    }
  }
}
.getting-started-section {
  [class*="col-"] {
    counter-reset: section;
  }

  &.carefirst {
    .carefirst-heading-1 {
      color: $care-first-tertiary;
      text-align: center;
      @include respond(desktop) {
        margin: 0 0 rem(80) 0;
      }
      @include respond(ipadMobile) {
        margin-bottom: rem(40);
      }
    }
    .carefirst-heading-2 {
      color: $care-first-tertiary;
      @include respond(desktop) {
        margin: 0 0 rem(60) 0;
      }
      @include respond(ipadMobile) {
        margin-bottom: rem(30);
      }
    }
    .gettingstartedcard {
      @include respond(ipadMobile) {
        padding-left: 3.5rem;
      }
      @include respond(mobile) {
        display: flex;
        flex-direction: column;
        padding-left: 0rem;
        align-items: center;
      }
      &::before {
        color: $care-first-grey-d6;
        border-color: $care-first-grey-d6;
        transform: translateY(0.4rem);
        font-size: rem(28);
        line-height: rem(38);
        @include respond(desktop) {
          width: rem(43);
          height: rem(43);
        }
        @include respond(mobile) {
          display: none;
        }
      }
      &.active {
        &::before {
          border-color: $care-first-secondary;
          color: $care-first-secondary;
        }
        h4 {
          color: $care-first-secondary;
          @include respond(mobile) {
            &::before {
              border-color: $care-first-secondary;
              color: $care-first-secondary;
            }
          }
        }
      }
      h4 {
        color: $care-first-grey-d6;

        font-family: "Oswald", sans-serif;
        font-weight: 400;

        line-height: rem(50);
        @include respond(desktop) {
          font-size: rem(40);
        }
        @include respond(ipadMobile) {
          font-size: rem(30);
        }
        @include respond(mobile) {
          padding-left: 3rem;
          position: relative;
          min-width: 19rem;
          text-align: center;
          &::before {
            counter-increment: section;
            content: counter(section);
            position: absolute;
            top: 0;
            left: 0;
            border: 1px solid $care-first-grey-d6;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $care-first-grey-d6;
            opacity: 0.7;
            transform: translateY(0.6rem);
            width: 2rem;
            height: 2rem;
            font-size: 1rem;
          }
        }
      }
      p {
        color: $care-first-tertiary;
        line-height: rem(34);
        @include respond(desktop) {
          font-size: rem(24);
          margin-top: rem(20);
        }
        @include respond(ipadMobile) {
          font-size: rem(18);
        }
        @include respond(mobile) {
          text-align: center;
        }
      }
    }
  }
}
.getting-started-section,
.section {
  @include respond(desktop) {
    padding: rem(95) 0;
  }
  @include respond(ipadMobile) {
    padding: rem(65) rem(17);
  }
}
.getting-started-section {
  @include respond(ipadMobile) {
    padding: rem(115) rem(17) rem(65) rem(17);
  }
  .default-heading-2 {
    margin-bottom: rem(55);
  }
}
.everything-you-need-section {
  background: $default-primary-gradient;

  @include respond(desktop) {
    padding: rem(170) 0;
    .img-resize {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(-20%, -50%);
      max-width: 130%;
    }
  }
  @include respond(ipadMobile) {
    padding: rem(65) rem(17);
  }

  .default-heading-2 {
    @include respond(desktop) {
      margin-bottom: 1.714286rem;
    }
    @include respond(ipadMobile) {
      margin-bottom: 1rem;
    }
  }
}
.surveynow-section {
  .default-heading-2 {
    @include respond(desktop) {
      margin-bottom: 1.714286rem;
    }
    @include respond(ipadMobile) {
      margin-bottom: 1rem;
      padding: 0 rem(17);
    }
  }
}

.two-week-goal-section {
  @include respond(mobile) {
    // padding-bottom: 34rem;
  }
}
.surveynow-section-carefirst {
  .care-first-btn-primary {
    @include respond(mobile) {
      margin-top: rem(30);
    }
  }
}

.care-first-grey-bg {
  background-color: $care-first-bg;
}
.bg-default-grey {
  background-color: $default-grey-f2;
}
.video-carfirst-section {
  .carefirst-heading-2 {
    @media only screen and (min-width: 992px) {
      margin-bottom: 4.642rem;
    }
    @media only screen and (max-width: 992px) {
      margin-bottom: 1rem;
    }
  }
}
.care-first-dark-bg {
  background-color: $care-first-dark;
}

.video-section-wrapper {
  .video-section {
    .carefirst-heading-2 {
      text-align: center;
      color: $care-first-tertiary;
    }
    .video-slider-card__desc {
      color: $care-first-tertiary;
      h3 {
        font-size: rem(28);
        line-height: rem(38);
        @media only screen and (max-width: 767px) {
          font-size: rem(20);
        }
      }
      h4 {
        font-size: rem(24);
        line-height: rem(34);
      }
    }
  }
}

.countdown-section {
  .countdown-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 60%;
    background: linear-gradient(307.25deg, rgba(46, 182, 149, 0.5) 15.07%, rgba(30, 165, 222, 0.5) 84.98%);
    padding: 2rem;
    @media only screen and (max-width: 992px) {
      padding: 0.5rem;
    }
  }
  .countdown-img {
    @media only screen and (min-width: 2800px) {
      width: 100%;
      height: 72rem;
      object-fit: cover;
      object-position: center;
    }
    @media only screen and (max-width: 767px) {
      width: 100%;
      height: 20rem;
      object-fit: cover;
      object-position: center;
    }
  }
  .default-heading-1 {
    color: $white;
    font-size: rem(100);
    @media only screen and (max-width: 992px) {
      font-size: rem(50);
    }
    @media only screen and (max-width: 767px) {
      font-size: rem(25);
    }
  }

  .default-heading-2 {
    color: $white;
    &.countdown-title {
      margin-bottom: rem(16);
      @media only screen and (max-width: 767px) {
        margin-bottom: rem(4);
      }
    }
  }
  .default-heading-3 {
    color: $white;
    font-weight: 400;
    @media only screen and (max-width: 992px) {
      font-size: rem(16);
    }
    @media only screen and (max-width: 767px) {
      font-size: rem(12);
    }
  }
  .time-container {
    padding-top: rem(8);
  }
  .time-divider {
    padding: 0 rem(100);
    @media only screen and (max-width: 992px) {
      padding: 0 rem(50);
    }
    @media only screen and (max-width: 767px) {
      padding: 0 rem(25);
    }
  }
  .time-seconds {
    transform: scale(0.94);
    animation: scale 1s infinite cubic-bezier(0.5, 1, 0.89, 1);
    @keyframes scale {
      100% {
        transform: scale(1);
      }
    }
  }
}
.footer-section {
  .social {
    &:hover {
      color: $befault-primary;
      img {
        filter: hue-rotate(180deg);
      }
    }
  }
}
